import React from "react";
import Layout from '@pages/Layout';
import img_coin_left from '@images/coin-left.png'
import img_coin_right from '@images/coin-right.png'

const Comming = () => {
  return (
    <div className="home-page font-nidus_sans__reguar tracking-widest">
      <Layout>
        <div className={`w-full absolute top-1/2 -translate-y-1/2 sm:-translate-y-1/3 lg:-translate-y-1/2`}>
          <div className="relative w-11/12 sm:w-10/12 lg:w-11/12 xl:w-10/12 mx-auto">
            <img src={img_coin_left} alt="coin" className="absolute -top-6 left-0 w-10 sm:w-16 md:w-20 lg:w-1/12" />
            <div className="flex flex-col justify-center items-center w-11/12 xl:w-9/12 mx-auto text-white xl:leading-loose 2xl:leading-tight pt-8 md:pt-12 whitespace-nowrap">
              <h2 className="font-phage__regular sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-shadow">
                PLAY! WIN! INVEST!
              </h2>
              <h1 className="font-phage__rough text-4xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl">
                 ON SALE <span className="text-yellow">SOON</span>
              </h1>
              <h2 className="font-phage__regular text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-shadow pt-2 sm:pt-4 xl:pt-4 text-center">
                <div className="text-base sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl"></div>
              </h2>
            </div>
            <img src={img_coin_right} alt="coin" className="absolute w-10 sm:w-16 md:w-20 lg:w-1/12 right-0 -bottom-6" />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Comming;
