import Coming from './Coming'
import Presale from './Presale'

const Home = () => {
  const appState = Number(process.env.REACT_APP_STATE);

  if(appState === 1) {
    return <Coming/>
  } else if(appState === 2) {
    return <Presale/>
  }
}

export default Home;