import React, { useEffect, useMemo, useState } from "react";
import img_box_time from '@images/box_time.png'
import img_dot from '@images/dot.png'

const Timer = ({gtdTime}) => {
  const [timeMin, setTimeMin] = useState(Math.floor(gtdTime / 60));
  const [timeSec, setTimeSec] = useState(gtdTime % 60);

  useEffect(() => {
    let i = 0;
    const handleInterval = setInterval(() => {
      if(i < gtdTime) {
        i++;
        const diff = gtdTime - i;
        setTimeMin(Math.floor(diff / 60))
        setTimeSec(diff % 60)
      }
    }, 1000)

    return () => {
      clearInterval(handleInterval)
    }
  }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      <h3 className="text-yellow text-2xl lg:text-3xl 2xl:text-4xl text-center">
        GTD. Allocation <br /> EXPIRES in:
      </h3>
      <div className="flex justify-between items-center">
        <div className="relative">
          <h1 className="text-4xl lg:text-6xl 2xl:text-7xl absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/3 text-center leading-6 lg:leading-8 2xl:leading-10">
            {timeMin} <br />
            <span className="text-xl lg:text-3xl 2xl:text-4xl">MIN</span>
          </h1>
          <img src={img_box_time} alt="time" />
        </div>
        <img src={img_dot} alt="dot" className="h-20 lg:h-24 xl:h-full" />
        <div className="relative">
          <h1 className="text-4xl lg:text-6xl 2xl:text-7xl absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/3 text-center leading-6 lg:leading-8 2xl:leading-10">
            {timeSec} <br />
            <span className="text-xl lg:text-3xl 2xl:text-4xl">SEC</span>
          </h1>
          <img src={img_box_time} alt="time" />
        </div>
      </div>
    </div>
  )
}

export default Timer;