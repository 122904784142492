import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Web3 from 'web3'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import { useConnectWallet, init, useSetChain } from '@web3-onboard/react'
import wallet_logo from '@images/wallet_logo.png'

const NETWORK_ID = parseInt(process.env.REACT_APP_CHAIN_ID)
const HEX_NETWORK_ID ='0x' + NETWORK_ID.toString(16)
const NETWORK_NAME = process.env.REACT_APP_CHAIN_NAME
const RPC_URL = process.env.REACT_APP_RPC_URL

const injected = injectedModule()
const walletConnect = walletConnectModule()
const walletInfo = {
  wallets: [
    injected,
    walletConnect
  ],
  chains: [
    {
      id: '0x38',
      token: 'BNB',
      label: 'BNB Chain Mainnet',
      rpcUrl: `https://bsc-dataseed4.binance.org`
    },
    {
      id: '0x61',
      token: 'tBNB',
      label: 'BNB Chain Testnet',
      rpcUrl: `https://data-seed-prebsc-2-s3.binance.org:8545`
    }
  ],
  connect: {
    showSidebar: true
  },
  appMetadata: {
    name: "Nexus War",
    icon: wallet_logo,
    description: "Wallet Connections"
  },
  accountCenter: {
    desktop: {
      enabled: false,
      minimal: true
    },
    mobile: {
      enabled: false,
      minimal: true
    }
  },
}

init(walletInfo)

const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 })

const ChainID = NETWORK_ID
const ChainName = NETWORK_NAME

const getWeb3 = () => {
  if (typeof window !== "undefined") {
    const web3 = new Web3(window.ethereum || httpProvider)

    return web3
  } else {
    return null
  }
}

const getProviderWeb3 = () => {
  if (typeof window !== "undefined") {
    const web3 = new Web3(httpProvider)

    return web3
  } else {
    return null
  }
}

const UseWalletContext = React.createContext(null)

const useWallet = () => {
  const walletContext = useContext(UseWalletContext)

  if (walletContext === null) {
    throw new Error(
      'useWallet() can only be used inside of <UseWalletProvider />, ' +
      'please declare it at a higher level.'
    )
  }

  const { walletData } = walletContext

  return useMemo(() => ({ ...walletData }), [
    walletData
  ])
}

const UseWalletProvider = ({
  chainId,
  chainName,
  children
}) => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const [{ }, setChain] = useSetChain()
  const walletContext = useContext(UseWalletContext)
  const web3 = getWeb3()

  if (walletContext !== null) {
    throw new Error('<UseWalletProvider /> has already been declared.')
  }

  const [connected, setConnected] = useState(false)
  const [walletAddress, setWalletAddress] = useState('')
  const [error, setError] = useState(null)
  
  useEffect(() => {
    if (wallet?.provider) {
      if(connecting)
         setChain({ chainId: parseInt(chainId) });

      let networkId = parseInt(wallet?.chains[0].id)
      if (networkId !== parseInt(chainId)) {
        setError(`You should choose ${chainName}!`)
        disconnect(wallet)
      } else {
        setWalletAddress(wallet?.accounts[0].address)
      }
      setConnected(networkId === parseInt(chainId))
    } else {
      setWalletAddress('')
      setConnected(false)
    }
  }, [wallet])

  useEffect(() => {
    walletInitialize()

    if (typeof window !== "undefined") {
      if (window.ethereum) {

        window.ethereum.on('chainChanged', handleNetworkChange);
        window.ethereum.on('disconnect', disconnectHandler);
        window.ethereum.on('accountsChanged', disconnectHandler);
      }
    }

  }, [])

  const walletInitialize = async () => {
    const _chainId = await web3.eth.getChainId()
    const _address = await web3.eth.getAccounts()
    if (_address[0] && _chainId === ChainID) {
      setConnected(true)
      setWalletAddress(_address[0])
    }
  }

  const connectHandler = async () => {
    connect()
  }

  const disconnectHandler = () => {
    if(wallet) {
      disconnect(wallet)
    }
    setConnected(false)
    setWalletAddress('')
  }

  const handleNetworkChange = (networkId) => {
    if (networkId !== HEX_NETWORK_ID) {
      disconnectHandler();
      setError(`You should choose ${chainName}!`)
    } else {
      walletInitialize()
    }
  }

  const walletData = useMemo(
    () => ({
      chainId,
      connected,
      connecting,
      walletAddress,
      connectHandler,
      disconnectHandler,
      error
    }),
    [
      chainId,
      connected,
      connecting,
      walletAddress,
      connectHandler,
      disconnectHandler,
      error
    ]
  )

  return (
    <UseWalletContext.Provider
      value={{
        walletData
      }}
    >
      {children}
    </UseWalletContext.Provider>
  )
}

UseWalletProvider.defaultProps = {
  chainId: ChainID,
  chainName: ChainName
}

export {
  ChainID,
  getWeb3,
  getProviderWeb3,
  UseWalletProvider,
  useWallet
}
