import React, { useEffect, useMemo, useState } from "react";
import Layout from '@pages/Layout';
import Timer from './Timer';
import { useWallet, getWeb3 } from '@utils/walletHelper'
import { getInitialInfo } from "@utils/contract";
import addresses from "@utils/address"
import json_usdc from "@abis/usdc.json"
import json_sale from "@abis/privateSale.json"
import img_coin_left from '@images/coin-left.png'
import img_coin_right from '@images/coin-right.png'
import img_box_tier from '@images/box_tier.png'
import img_box_panel from '@images/box_panel.png'
import icon_success from '@images/icon_success.png'
import icon_error from '@images/icon_error.png'

const Presale = () => {
  const decimal = 4;
  const { connected, connectHandler, walletAddress } = useWallet();
  const [isUsdc, setIsUsdc] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [remain, setRemain] = useState(0);
  const [amount, setAmount] = useState(0);
  const [mintAmount, setMintAmount] = useState(0);
  const [gtdTime, setGtdTime] = useState(0);
  const [initialInfo, setInitialInfo] = useState({
    tier: 0,
    price: 0,
    maxPrice: 0,
    supply: 0,
    availableAmount: 0,
    remainAmount: 0,
    remainPrice: 0,
    isSoldOut: false,
    isSpaceListed: true,
    isPaused: false,
    isApproved: true
  })

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })

  const [isPending, setIsPending] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const loadHandler = async () => {
      setIsLoading(true)
      const re = await initialHandler()
      setGtdTime(re.gtdTime)
      setAmount(Number((re.maxPrice).toFixed(decimal)))
      setIsLoading(false)
    }

    loadHandler()
  }, [connected])

  useEffect(() => {
    const handleInterval = setInterval(() => {
      initialHandler()
    }, 15000)

    return () => {
      clearInterval(handleInterval)
    }
  })

  const initialHandler = async () => {
    const info = await getInitialInfo(walletAddress);
    setInitialInfo(info)

    return info
  }

  useEffect(() => {
    setTotalAmount(isUsdc ? initialInfo.maxPrice : initialInfo.availableAmount)
    setRemain(isUsdc ? initialInfo.remainPrice : initialInfo.remainAmount)
  }, [initialInfo, isUsdc])

  const isBlocked = useMemo(() => {
    return initialInfo.isPaused || isLoading || isPending || initialInfo.isSoldOut
  }, [initialInfo, isLoading]);

  const inputHandler = (e) => {
    if (isBlocked) return;
    let val = Number(e.target.value);
    val = val < 0 ? 0 : val > totalAmount ? totalAmount : val;
    val = val.toString().split('.')[1]?.length > decimal ? val.toFixed(decimal) : val;
    setAmount(Number(val));
    const _mintAmount = !isUsdc ? val : Math.floor(val / initialInfo.price);
    setMintAmount(_mintAmount);
  }

  const nxusClickHandler = () => {
    if (isBlocked || !isUsdc) return;

    setIsUsdc(false);
    const _mintAmount = Math.floor(amount / initialInfo.price)
    setMintAmount(_mintAmount)
    let _amount = Math.floor(amount / initialInfo.price)
    _amount = _amount < 0 ? 0 : _amount > initialInfo.availableAmount ? initialInfo.availableAmount : _amount;
    setAmount(_amount);
  }

  const usdcClickHandler = () => {
    if (isBlocked || isUsdc) return;

    setIsUsdc(true);
    const _mintAmount = amount
    setMintAmount(_mintAmount)
    let _amount = amount * initialInfo.price;
    _amount = _amount < 0 ? 0 : _amount > initialInfo.maxPrice ? initialInfo.maxPrice : _amount;
    setAmount(Number(_amount.toFixed(decimal)));
  }

  const mintHandler = async () => {
    if (isBlocked || (mintAmount === 0 && initialInfo.isApproved)) return;

    const web3 = await getWeb3();

    if (!initialInfo.isApproved) {
      const contract = new web3.eth.Contract(json_usdc, addresses.usdc);
      const allowAmount = "175000000000000000000000";
      try {
        await contract.methods.approve(addresses.sale, allowAmount).send({ from: walletAddress })
          .on('transactionHash', (receipt) => {
            setIsPending(true)
            displayNotify("info", "Your transaction is processing.")
          })
          .on('receipt', (receipt) => {
            setInitialInfo(prevState => ({
              ...prevState,
              isApproved: true
            }))
            setIsPending(false)
            displayNotify("success", "Transaction was successful.")
          })
          .on('error', () => {
            setIsPending(false)
            displayNotify("error", "Transaction cancelled.")
          });
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const contract = new web3.eth.Contract(json_sale, addresses.sale);
        await contract.methods.buy(mintAmount).send({ from: walletAddress })
          .on('transactionHash', (receipt) => {
            setIsPending(true)
            displayNotify("info", "Your transaction is processing.")
          })
          .on('receipt', (receipt) => {
            setIsPending(false)
            initialHandler()
            displayNotify("success", "Transaction was successful.")
          })
          .on('error', () => {
            setIsPending(false)
            displayNotify("error", "Transaction cancelled.")
          });
      } catch (err) {
        console.log(err)
      }
    }
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })

    setTimeout(() => {
      setAlertState({
        open: false,
        message: '',
        severity: '',
      })
    }, 5000)
  }

  const PanelItem = ({ title }) => {
    return (
      <div className="absolute top-1/2 -translate-y-1/2 flex justify-center items-center w-full">
        <h1 className="text-white font-phage__rough text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl text-center">
          {title}
        </h1>
      </div>
    )
  }

  return (
    <div className="home-page font-nidus_sans__reguar tracking-widest">
      <Layout>
        <div className={`w-full pt-32 sm:pt-40 md:pt-48 xl:pt-56`}>
          <div className="relative w-11/12 sm:w-10/12 lg:w-11/12 xl:w-10/12 mx-auto">
            <img src={img_coin_left} alt="coin" className="absolute -top-6 left-0 w-10 sm:w-14 md:w-16 lg:w-20 xl:w-1/12" />
            <div className="flex flex-col justify-center items-center w-11/12 xl:w-9/12 mx-auto text-white xl:leading-loose 2xl:leading-tight pt-4 sm:pt-12 whitespace-nowrap">
              <h2 className="font-phage__regular sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-shadow">
                PRIVATE ROUND
              </h2>
              <h1 className="font-phage__rough text-4xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl">
                PRESALE <span className="text-yellow">{initialInfo.tier > 0 ? "LIVE" : "SOON"}</span>
              </h1>
            </div>
            <img src={img_coin_right} alt="coin" className="absolute w-10 sm:w-14 md:w-16 lg:w-20 xl:w-1/12 right-0 -bottom-10 sm:-bottom-16 xl:-bottom-24" />
          </div>
          <div className="mt-8 sm:mt-16 lg:mt-24 font-phage__rough text-white uppercase sm:pb-2 md:pb-0">
            {
              initialInfo.tier > 0 && (
                <div className="flex justify-between items-center w-4/5 sm:w-5/6 md:w-2/3 xl:w-1/2 mx-auto gap-6 sm:gap-10 2xl:gap-16 flex-col sm:flex-row">
                  <div className="flex flex-col justify-center items-center pt-10">
                    <h3 className="text-2xl lg:text-3xl 2xl:text-4xl">
                      Tier {initialInfo.tier}
                    </h3>
                    <div className="relative w-2/3 sm:w-full mx-auto">
                      <h1 className="text-4xl lg:text-5xl 2xl:text-6xl absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-center">
                        {initialInfo.price} <br />
                        <span className="text-4xl lg:text-5xl 2xl:text-6xl">USDC</span>
                      </h1>
                      <img src={img_box_tier} alt="box" />
                    </div>
                  </div>
                  <Timer
                    gtdTime={gtdTime}
                  />
                </div>
              )
            }
            <div className={`relative mt-6 sm:mt-12 flex justify-center w-full sm:w-10/12 mx-auto ${initialInfo.isSoldOut || !initialInfo.isSpaceListed ? "h-64 mt-10" : "h-96"} lg:h-auto`}>
              {
                initialInfo.isSoldOut ? (
                  <PanelItem
                    title={initialInfo.tier > 0 ? "Sold Out" : "NOT STARTED"}
                  />
                ) : !initialInfo.isSpaceListed ? (
                  <PanelItem
                    title="NOT ON SPACELIST"
                  />
                ) : (
                  <div className="flex flex-col justify-center items-center w-full h-full gap-4 md:gap-4 xl:gap-8 absolute top-0 font-phage__regular">
                    {alertState.open && (
                      <div className="flex items-center pl-8 sm:pl-0">
                        {
                          alertState.severity === 'success' && (
                            <img src={icon_success} alt="notify" className="mr-2 h-7 sm:h-10 md:h-12 2xl:h-14" />
                          )
                        }
                        {
                          alertState.severity === 'error' && (
                            <img src={icon_error} alt="notify" className="mr-2 h-7 sm:h-10 md:h-12 2xl:h-14" />
                          )
                        }
                        <h3 className={`${alertState.severity === 'info' ? "text-center mr-8 sm:mr-0" : ""} sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-5`}>
                          {alertState.message}
                        </h3>
                      </div>
                    )}
                    <div className="flex justify-between items-center gap-4 sm:gap-6 flex-col sm:flex-row w-4/5 sm:w-5/6 md:w-4/5 lg:w-2/3 mx-auto">
                      <div className={`w-full relative ${isBlocked ? "opacity-70 cursor-not-allowed" : ""}`}>
                        <input type="number" min="0" max={totalAmount} placeholder={isUsdc ? "Spend" : "Amount"} className={`text-xl md:text-2xl xl:text-3xl rounded-xl px-6 py-2 md:py-3 border-blue hover:border-blue outline-none border-2 bg-transparent w-full pr-24 lg:pr-32 ${isBlocked ? "cursor-not-allowed" : ""}`} value={amount} onChange={(e) => inputHandler(e)} step={isUsdc ? '0.0001' : '0'} />
                        <h3 className="absolute top-1/2 -translate-y-1/2 right-6 text-xl md:text-2xl xl:text-3xl text-gray">
                          / {totalAmount}
                        </h3>
                      </div>
                      <div className="flex gap-4 sm:gap-6">
                        <div className={`px-4 py-1 text-xl md:text-2xl xl:text-3xl border-2 border-blue cursor-pointer ${!isUsdc ? "opacity-100" : "opacity-60"} ${isBlocked ? "opacity-70 cursor-not-allowed" : ""}`} onClick={nxusClickHandler}>
                          NXUS
                        </div>
                        <div className={`px-4 py-1 text-xl md:text-2xl xl:text-3xl border-2 border-blue cursor-pointer ${isUsdc ? "opacity-100" : "opacity-60"} ${isBlocked ? "opacity-70 cursor-not-allowed" : ""}`} onClick={usdcClickHandler}>
                          USDC
                        </div>
                      </div>
                    </div>
                    <button className={`text-2xl md:text-3xl xl:text-4xl bg-green px-16 py-2 rounded-xl uppercase tracking-widest hover:scale-105 transform duration-150 mt-2 ${alertState.open ? "sm:mt-4" : "sm:mt-0"} ${isBlocked ? "opacity-70 cursor-not-allowed" : ""}`} onClick={connected ? mintHandler : connectHandler}>
                      {!connected ? "connect" : isLoading ? "Loading ..." : isPending ? "Pending ..." : initialInfo.isApproved ? "Purchase" : "Allow USDC"}
                    </button>
                  </div>
                )
              }
              <img src={img_box_panel} alt="panel" className="w-full" />
            </div>
          </div>
        </div>
      </Layout >
    </div >
  )
}

export default Presale;
