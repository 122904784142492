import React from "react";
import { Link } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import { useWallet } from '@utils/walletHelper'
import { Disclosure } from "@headlessui/react"
import logo from '@images/logo.png'
import icon_discord from '@images/icons/discord.png'
import icon_twitter from '@images/icons/twitter.png'
import btn_disconnect from '@images/btn-disconnect.png'
import logo_sm from '@images/wallet_logo.png'

const Header = () => {
  const { connected, connectHandler, disconnectHandler, walletAddress } = useWallet()

  const displayAddress = (address) => {
    return address.slice(0, 4) + '...' + address.slice(-4)
  }

  return (
    <Disclosure
      as="nav"
      className={`top-0 absolute z-50 w-full px-0`}
    >
      {({ open }) => (
        <div className="flex justify-between items-center px-4 sm:px-8 py-6">
          <Link to="/">
            <img src={logo} alt="logo" className="h-10 sm:h-14 lg:h-16" />
          </Link>
          <div className="justify-between items-center gap-6 h-12 py-1 hidden lg:flex">
            <a href="https://discord.gg/abbVCpst6z" className="h-full hover:scale-105 transform duration-150">
              <img src={icon_discord} alt="icon discord" className="h-full" />
            </a>
            <a href="https://twitter.com/nexuswar_io" target="_blank" rel="noreferrer" className="h-full hover:scale-105 transform duration-150">
              <img src={icon_twitter} alt="icon twitter" className="h-full" />
            </a>
            <a href="https://docs.nexuswar.io/" target="_blank" rel="noreferrer" className="text-white bg-black px-8 py-2 rounded-xl border-green border-2 uppercase h-full tracking-widest hover:scale-105 transform duration-150">
              Docs
            </a>
            {
              connected ? (
                <div className="flex justify-center items-center h-full gap-2">
                  <div className="text-white bg-green px-6 py-2 rounded-xl uppercase h-full tracking-widest">
                    <div className="wallet-address">
                      {displayAddress(walletAddress)}
                    </div>
                  </div>
                  <img src={btn_disconnect} alt="disconnect" onClick={disconnectHandler} className="h-10 cursor-pointer hover:scale-105 transform duration-150"/>
                </div>
              ) : (
                <button className="btn-connect text-white bg-green px-6 py-2 rounded-xl uppercase h-full tracking-widest hover:scale-105 transform duration-150" onClick={connectHandler}>
                  Connect Wallet
                </button>
              )
            }
          </div>
          <div className="lg:hidden text-white absolute right-2 sm:right-6 z-50 scale-90 sm:scale-95 lg:scale-100">
            <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
              <Hamburger toggled={open} />
            </Disclosure.Button>
          </div>
          <Disclosure.Panel className="lg:hidden bg-primary absolute bg-black w-full left-0 top-0">
            <div className="flex flex-col justify-center items-center gap-4 py-10">
              <a href="https://discord.gg/abbVCpst6z" className="h-10 hover:scale-105 transform duration-150">
                <img src={icon_discord} alt="icon discord" className="h-full" />
              </a>
              <a href="https://twitter.com/nexuswar_io" target="_blank" rel="noreferrer" className="h-10 hover:scale-105 transform duration-150">
                <img src={icon_twitter} alt="icon twitter" className="h-full" />
              </a>
              <a href="https://docs.nexuswar.io/" target="_blank" rel="noreferrer" className="text-white bg-black px-8 py-2 rounded-xl border-green border-2 uppercase h-10 tracking-widest hover:scale-105 transform duration-150">
                Docs
              </a>
              {
                connected ? (
                  <div className="flex justify-center items-center gap-2">
                    <div className="text-white bg-green px-6 py-2 rounded-xl uppercase h-10 tracking-widest">
                      {displayAddress(walletAddress)}
                    </div>
                    <img src={btn_disconnect} alt="disconnect" onClick={disconnectHandler} className="h-10 cursor-pointer hover:scale-105 transform duration-150"/>
                  </div>
                ) : (
                  <button className="btn-connect text-white bg-green px-6 py-2 rounded-xl uppercase h-10 tracking-widest hover:scale-105 transform duration-150" onClick={connectHandler}>
                    Connect Wallet
                  </button>
                )
              }
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default Header;
