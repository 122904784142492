import { getProviderWeb3 } from "@utils/walletHelper"
import addresses from "@utils/address"
import json_usdc from "@abis/usdc.json"
import json_sale from "@abis/privateSale.json"

export const getInitialInfo = async (walletAddress) => {
  const web3 = await getProviderWeb3();
  const saleContract = new web3.eth.Contract(json_sale, addresses.sale);
  const usdcContract = new web3.eth.Contract(json_usdc, addresses.usdc);
  const tier = await saleContract.methods.currentTier().call();
  const price = await saleContract.methods.tierPrice(tier).call();
  const supply = await saleContract.methods.tierSupply(tier).call();
  const salePaused = await saleContract.methods.salePaused().call();
  const saleEnded = await saleContract.methods.saleEndedForever().call();
  const isSpacelisted = walletAddress === '' ? false : await saleContract.methods.isSpacelisted(walletAddress).call();
  const leftAmount = await saleContract.methods.tierToAmountLeft(tier).call();

  const tierStartTime = await saleContract.methods.tierStartTimestamp(tier).call();
  const blockNumber = await web3.eth.getBlockNumber();
  const blockInfo = await web3.eth.getBlock(blockNumber);
  const currentTime = blockInfo.timestamp;
  const gtdTime = Number(tierStartTime) + 3600 - currentTime;
  const isLimited = (Number(tierStartTime) + 3600) > currentTime;
  const allowAmount = await saleContract.methods.tierToAllocation(tier).call();
  const boughtAmount = walletAddress === '' ? 0 : await saleContract.methods.tierAndAddressToAmountBought(tier, walletAddress).call();
  const availableAmount = !isLimited ? leftAmount : (Number(allowAmount) - Number(boughtAmount));
  const remainAmount = !isLimited ? leftAmount : Number(allowAmount);
  const allowance = walletAddress === '' ? 0 : await usdcContract.methods.allowance(walletAddress, addresses.sale).call();

  return {
    tier: Number(tier),
    price: Number(price) / 1e18,
    maxPrice: Number((price / 1e18 * availableAmount / 1e18).toFixed(4)),
    supply: Number(supply) / 1e18,
    availableAmount: Number(Math.floor(availableAmount / 1e18)),
    remainAmount: Number(Math.floor(remainAmount / 1e18)),
    remainPrice: Number((price / 1e18 * remainAmount / 1e18).toFixed(4)),
    gtdTime: gtdTime < 0 ? 0 : gtdTime,
    isSoldOut: leftAmount > 0 ? false : true,
    isSpaceListed: gtdTime < 0 ? true : isSpacelisted,
    isPaused: salePaused || saleEnded,
    isApproved: walletAddress === '' ? true : Number(allowance) > 0
  }
}

export const addTokenHandler = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', 
        options: {
          address: addresses.nexus, 
          symbol: 'NXUS',
          decimals: 18, 
          image: 'https://nexuswar.io/logo192.png', 
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}
