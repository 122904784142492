import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
  const appState = process.env.REACT_APP_STATE;

  return (
    <>
      <Header/>
      { children }
      { appState > 1 && <Footer/> }
    </>
  )
}

export default Layout;