const addresses = {
  nexus: {
    56: '0xC76Af4794B410643E5F5355b0E737a88460f552F',
    97: '0xC76Af4794B410643E5F5355b0E737a88460f552F'
  },
  usdc: {
    56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    97: '0x64544969ed7EBf5f083679233325356EbE738930'
  },
  sale: {
    56: '0xACa8919b5e2544e2b3E7D5052F48Ba9da46CC8D1',
    97: '0xC9a227Db913a2E9F3396ee34103165088431ef4B'
  }
}

export {
  addresses
}
 
